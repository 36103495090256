import {InlineWidget} from "react-calendly";
import {styled} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

const PageSettings = {

    backgroundColor: "ffffff",
    primaryColor: process.env.REACT_APP_PRIMARY_COLOUR,
    textColor: '2d2926'

}


const WidgetHolder = styled('div')(({ theme }) => ({

    backgroundColor: "transparent",
    [theme.breakpoints.between('xs', 'sm')]: {

        width: "100%",
        height: "100%",
        backgroundColor: "white",

    },

    [theme.breakpoints.between('sm', 'md')]: {

        width: "100%",
        marginBottom: "75px",

    },
    [theme.breakpoints.between('md', 'lg')]: {

        width: "100%"

    },
    [theme.breakpoints.up('lg')]: {

        width: "100%",
    }

}))


const Main = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const height = matches ? "1500px": "1200px";

    return (
        <WidgetHolder theme={theme}>
            <InlineWidget pageSettings={PageSettings} styles={{height: height, marginTop: "5rem"}} url={process.env.REACT_APP_CALENDLY_LINK}/>
        </WidgetHolder>
    )

}

export default Main;
