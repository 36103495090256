import './App.css';
import {Fragment, useEffect} from "react";
import TopBar from "./components/TopBar";
import {createTheme, ThemeProvider} from "@mui/material";
import BottomBar from "./components/BottomBar";
import Main from "./pages/Main";
import {Helmet} from "react-helmet";
import {animateScroll as scroll} from "react-scroll";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

function checkHexCodeBackground(background) {

    return /^#[0-9A-F]{6}$/i.test(background);

}


const styles = {

    sectionImage: {

        background: checkHexCodeBackground(process.env.REACT_APP_BACKGROUND_IMAGE) ? process.env.REACT_APP_BACKGROUND_IMAGE : `url(${process.env.REACT_APP_BACKGROUND_IMAGE}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    backgroundContainer: {

        position: "fixed",
        top: "-100%",
        width: "100%",
        height: "100%",
        zIndex: "-1"

    }
};

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
            contrastText: 'white',
        }
    },
    typography: {
        p: {
            fontFamily: 'Arial',
            fontSize: 14,
            marginBottom: '0.25rem'
        }
    },
});

function App() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {

        let timerId;

        if (matches) {

            timerId = setTimeout(() => {

                scroll.scrollTo(250);

            }, 3000);


        }

        return () => clearTimeout(timerId);


    }, [])

  return (

      <Fragment >
          <ThemeProvider theme={customTheme}>
              <Helmet>
                  <title> {process.env.REACT_APP_CLUB_NAME} </title>
              </Helmet>
              <TopBar/>
              <section style={styles.sectionImage}>
                  <Main />
              </section>
              <BottomBar style={styles.stickToBottom} />
          </ThemeProvider>
      </Fragment>
  );
}

export default App;
