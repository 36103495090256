import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import aiaLogo from '../assets/aia_logo.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    toolbarMargin: {

        ...theme.mixins.toolbar,
        marginBottom: ".75em",
        [theme.breakpoints.down("md")] : {

            marginBottom: ".75em"

        },
        [theme.breakpoints.down("xs")] : {

            marginBottom: ".75em"

        }

    },

}))

const disable_aia_logo = process.env.REACT_APP_DISABLE_AIA_LOGO ? process.env.REACT_APP_DISABLE_AIA_LOGO : "NO"

export default function TopBar() {

    const classes = useStyles();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar elevation={1} position="fixed" sx={{ bgcolor: "#FFFFFF"}}>
                <Toolbar sx={{textAlign: "center", margin: "0.5rem "}} disableGutters>
                    {process.env.REACT_APP_CLUB_LOGO !== "NONE" &&
                        <Button
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 1}}
                        >
                            <img src={process.env.REACT_APP_CLUB_LOGO} alt="logo"
                                 height={`${process.env.REACT_APP_LOGO_SIZE}px`}/>
                        </Button>
                    }
                    <Button
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 1 }}
                    >
                        {disable_aia_logo === "NO" &&
                            <img src={aiaLogo} alt="aia-logo" height="50px"/>
                        }
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
